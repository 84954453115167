<template>
  <section>
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm1"
          :model="selectForm1"
          class="select_form"
        >
          <el-form-item label="年:">
            <el-input v-model="selectForm1.Year" placeholder="年"></el-input>
          </el-form-item>
          <el-form-item label="月:">
            <el-input v-model="selectForm1.Month" placeholder="月"></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="new_css calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
    
        @row-dblclick="handleEdit"
        @current-change="selectCurrentRow"
        class="new_table"
      >
        <el-table-column prop="CreateUserName" label="创建人">
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="CreateDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column label="年" prop="Year"></el-table-column>
        <el-table-column label="月" prop="Month"></el-table-column>
        <el-table-column label="状态" prop="State">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.State == '未锁定' ? 'success' : 'danger'"
              disable-transitions
            >
              {{ scope.row.State }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      title="编辑"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="UserName">
              <el-input
                type="text"
                v-model="SakaryForm.UserName"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
      <el-form-item label="身份证号" prop="IdCard">
		   <el-input type="text" v-model="SakaryForm.IdCard" :disabled="true">
      </el-input>
      </el-form-item>
   	</el-col> -->
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="其他补贴:" prop="OtherMoney">
              <el-input type="text" v-model="SakaryForm.OtherMoney"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其他扣款" prop="OtherSalary">
              <el-input type="text" v-model="SakaryForm.OtherSalary">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" prop="Remark">
              <el-input type="textarea" v-model="SakaryForm.Remark"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
         
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
        
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      title="薪资考勤"
      :visible.sync="InsuranceVisible"
      v-model="InsuranceVisible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-row>
        <el-col
          v-if="buttonList.length > 0"
          :span="24"
          class="organizetoolbar"
          style="padding-bottom: 0px"
        >
          <el-form :inline="true" ref="selectForm" :model="selectForm">
            <el-form-item label="名称:">
              <el-input
                v-model="selectForm.userName"
                placeholder="名称"
              ></el-input>
            </el-form-item>

            <el-form-item label="公司名称:">
              <el-select
                style="width: 100%"
                clearable
                v-model="selectForm.factory"
                placeholder="公司名称"
              >
                <el-option
                  v-for="item in PositionLists"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- 这个就是当前页面内，所有的btn列表 -->
            <el-form-item>
              <!-- 这里触发点击事件 -->

              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="getInsuranceData()"
                >查询</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                :disabled="isDisabled"
                @click="handleInEdit()"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                :disabled="isDisabled"
                @click="handleCommany()"
                >提交公司</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                :disabled="isDisabled"
                @click="handleDeductTax()"
                >减个税</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleExcelTax()"
                >导出excel</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table :data="InsuranceData"
                highlight-current-row
                border
                height="500"
                :row-class-name="tableRowClassName"
                @current-change="selectCurrentRow1"
                @selection-change="handleSelectionChange"
                style="width: 100%">
          <el-table-column fixed prop="UserName" label="名称"> </el-table-column>
          <el-table-column fixed prop="Factory" label="公司"> </el-table-column>
          <el-table-column fixed prop="UserDpt" label="部门"> </el-table-column>
          <el-table-column fixed prop="UserPost" label="岗位"> </el-table-column>
          <el-table-column prop="UserState" label="用户状态"> </el-table-column>
          <el-table-column prop="StateDate" label="状态时间" :formatter="formatStartTime"></el-table-column>
          <el-table-column prop="RegYear" label="所属年"> </el-table-column>
          <el-table-column prop="RegMonth" label="所属月"> </el-table-column>
          <!-- <el-table-column fixed label="身份证号" show-overflow-tooltip width="150" prop="IdCard" ></el-table-column> -->
          <el-table-column label="工资类型" width="120" prop="SalaryType"></el-table-column>
          <el-table-column label="用工性质" width="120" prop="ContractNature"></el-table-column>
          <el-table-column label="应出勤天数" width="120" prop="UserAttend"></el-table-column>
          <el-table-column label="实际出勤天数" prop="UserRealAttend"></el-table-column>
          <el-table-column label="办事处出勤天数" prop="OutAttend"></el-table-column>
          <el-table-column label="回公司天数" prop="BackFactoryDay"></el-table-column>
          <el-table-column label="探亲天数" prop="BackFamilyDay"></el-table-column>
          <el-table-column label="请假天数" prop="AskDay"></el-table-column>
          <el-table-column label="值班剩余" prop="OndutyDay"></el-table-column>
          <el-table-column label="加班天数" prop="AddDay"></el-table-column>
          <el-table-column label="夜班天数" prop="NigtyDay"></el-table-column>
          <el-table-column label="岗位工资" prop="WorkSalary"></el-table-column>
          <el-table-column label="职务工资" prop="PostSalary"></el-table-column>
          <el-table-column label="职称工资" prop="PostionSalary"></el-table-column>
          <el-table-column label="年功工资" prop="YearSalary"></el-table-column>
          <el-table-column label="证书补贴" prop="CertSalary"></el-table-column>
          <el-table-column label="探亲补贴" prop="BackFamilySalary"></el-table-column>
          <el-table-column label="质量补贴" prop="QualitySalary"></el-table-column>
          <el-table-column label="零工" prop="OddSalary"></el-table-column>
          <el-table-column label="值班日保健费" prop="OndutyCareSalary"></el-table-column>
          <el-table-column label="日保健费" prop="CareDaySalary"></el-table-column>
          <el-table-column label="保健费" prop="CareSalary"></el-table-column>
          <el-table-column label="日工资出勤天数" prop="DayAttend"></el-table-column>
          <el-table-column label="工人系数" prop="WorkerMod"></el-table-column>
          <el-table-column label="计件天数" prop="PieceDay"></el-table-column>
          <el-table-column label="日工资单价" prop="DaySalary"></el-table-column>
          <el-table-column label="日工资小计" prop="DaySalaryCount"></el-table-column>
          <el-table-column label="计件工资" prop="PieceSalary"></el-table-column>
          <el-table-column label="缺勤扣款" prop="LeaveSalary"></el-table-column>
          <el-table-column label="伙食单价" prop="EateDaySalary"></el-table-column>
          <el-table-column label="基本工资小计" prop="BasicSalary"></el-table-column>
          <el-table-column label="绩效工资" prop="PerfSalary"></el-table-column>
          <el-table-column label="实发职务绩效" prop="RealPositionPerformance"></el-table-column>
          <el-table-column label="值班补贴" prop="OnWorkSalary"></el-table-column>
          <el-table-column label="加班补贴" prop="AddWorkSalary"></el-table-column>
          <el-table-column label="晚班补贴" prop="NigtySalary"></el-table-column>
          <el-table-column label="特岗补贴" prop="SpecialSalary"></el-table-column>
          <el-table-column label="伙食补贴" prop="EateSalary"></el-table-column>
          <el-table-column label="驻外补贴" prop="OutsideSalary"></el-table-column>
          <el-table-column label="实际驻外补贴" prop="RealOutsideSalary"></el-table-column>
          <el-table-column label="车辆补贴" prop="BusMoney"></el-table-column>
          <el-table-column label="租房补贴" prop="HouseSalary"></el-table-column>
          <el-table-column label="其他补贴" prop="OtherMoney"></el-table-column>
          <el-table-column label="正负激励" prop="PowerSalary"></el-table-column>
          <el-table-column label="学历补贴" prop="EducationSub"></el-table-column>
          <el-table-column label="实际学历补贴" prop="RealEducationSub"></el-table-column>
          <el-table-column label="补贴小计" prop="SubsidySalary"></el-table-column>
          <el-table-column label="应发工资" prop="ShouldSalary"></el-table-column>
          <el-table-column label="个人欠款" prop="SellSalary"></el-table-column>
          <el-table-column label="保险扣除" prop="SafeMoney"></el-table-column>
          <el-table-column label="住房公积金扣除" prop="AccumuSalary"></el-table-column>
          <el-table-column label="个税基数扣除项" prop="PersonBasicSalary"></el-table-column>
          <el-table-column label="其他扣款" prop="OtherSalary"></el-table-column>
          <el-table-column label="个人所得税" prop="PersonTaxSalary"></el-table-column>
          <el-table-column label="爱心基金" prop="LoveMoney"></el-table-column>
          <el-table-column label="上半月以发放" prop="IssuedSalary"></el-table-column>
          <el-table-column label="备注" prop="Remark"></el-table-column>
          <el-table-column label="实发工资" fixed="right" prop="UserMoney"></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-size="pageSize1"
        layout="total, prev, pager, next, jumper,total"
        :page-count="total1"
        :total="totaldata1"
      >
      </el-pagination>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
//  import moment from 'moment/moment.js'
import * as validate from "../../../util/validate";
import {
  GetSalaryMonthList,
  AddSalaryMonth,
  DeleteSalaryMonth,
  UpdateSalaryMonth,
  GetSalaryReportList,
  UpdateSalaryReport,
  GetGroupFactoryList,
  SalaryReportSubmitInfo,
  SalaryReportDeductTax,
  SalaryMonthGetPersonHistoryList,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
    return {
      PositionLists: [],
      fileListUpload: [],
      searchVal: "",
      disbtn: false,
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      currentPage1: 1, // 当前页码
      total1: null, // 总条数
      totaldata1: null,
      pageSize1: 20, // 每页的数据条数
      page1: 1,
      isDisabled: false,
      SakaryVisible: false,
      InsuranceVisible: false,
      SakaryAllFormVisible: false,
      buttonListmsg: "",
      users: [],
      InsuranceData: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      multipleSelection: [],
      PId: "",
      selectForm: {
        userName: "",
        factory: "",
        year: "",
      },
      selectForm1: {
        Year: "",
        Month: "",
      },
      SakaryForm: [],
      filters: {
        name: "",
      },
    };
  },
  methods: {
    handleUpdate(){
      this.$api.UpdatePaymentAccount().then((res) => {
         if (res.data.success) {
          this.$message({
            message: "更新成功",
            type: "success",
            duration: 5000,
          });
         
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.State == "已提交") {
        return "warning-row";
      }
      return "";
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectCurrentRow1(val) {
      this.currentRow1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.page1 = val;
      this.getInsuranceData(false);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        Year: this.selectForm1.Year,
        Month: this.selectForm1.Month,
      };

      GetSalaryMonthList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getInsuranceData(isCurrent = true) {
      if (isCurrent) {
        this.page1 = 1;
      }
      let para = {
        page: this.page1,
        size: this.pageSize1,
        PId: this.PId,
        userName: this.selectForm.userName,
        factory: this.selectForm.factory,
      };
      GetSalaryReportList(para).then((res) => {
        console.log(res);
        this.total1 = res.data.response.pageCount;
        this.totaldata1 = res.data.response.dataCount;
        this.InsuranceData = res.data.response.data;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "未锁定") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }

      this.PId = row.Id;
      this.getInsuranceData();
      this.getGroupFactoryList();
      this.InsuranceVisible = true;
    },
    getGroupFactoryList() {
      let para = {
        PId: this.PId,
      };
      console.log(para)
      GetGroupFactoryList(para).then((res) => {
        console.log(res.data)
        this.PositionLists = res.data.response;
      });
    },
    handleInEdit() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "已提交") {
        this.$message({
          message: "已提交状态不允许修改",
          type: "error",
        });
        return;
      }

      this.SakaryForm = Object.assign({}, row);
      this.SakaryVisible = true;
    },
    handleCommany() {
      let row = this.currentRow1;
      if (!this.selectForm.factory) {
        this.$message({
          message: "请选择设置的公司！",
          type: "error",
        });
        return;
      }
      let para = {
        PId: this.PId,
        factory: this.selectForm.factory,
      };
      SalaryReportSubmitInfo(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "设置成功",
            type: "success",
            duration: 5000,
          });
          this.getInsuranceData();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleDeductTax() {
      let para = {
        PId: this.PId,
      };
      SalaryReportDeductTax(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 5000,
          });
          this.getInsuranceData();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleAdd() {
      AddSalaryMonth().then((res) => {
        console.log(res)
        if (res.data.success) {
          this.$message({
            message: "添加成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData();
        } else {
          this.$message({
            message: "添加失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "已锁定") {
        this.$message({
          message: "锁定状态的数据不允许删除！",
          type: "error",
        });
        return;
      }
      let para = {
        id: row.Id,
      };

      DeleteSalaryMonth(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData();
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleInDel() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
        });
        return;
      }

      let para = {
        id: row.Id,
      };

      DeleteSalaryExcel(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 5000,
          });

          this.getInsuranceData();
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleLock() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要锁定的一行数据！",
          type: "error",
        });
        return;
      }
      this.$confirm("确定要锁定吗？", "提示", {}).then(() => {
        UpdateSalaryMonth({ id: row.Id }).then((res) => {
          console.log(res);
          if (res.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 5000,
            });
            this.getData(false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    inputAllMoney(name) {
      if (!validate.validateIsMoney(this.SakaryAllForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          UpdateSalaryReport(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 5000,
              });
              this.SakaryVisible = false;
              this.getInsuranceData();
            } else {
              this.$message({
                message: "修改失败",
                type: "error",
                duration: 5000,
              });
            }
          });
        });
      });
    },
    handleExcelTax() {
      SalaryMonthGetPersonHistoryList({
        monthId: this.PId,
        factory: this.selectForm.factory,
      }).then((res) => {
        if (res.data.success) {
          console.log(res);
          var header = [
             "公司",             
             "部门",             
             "岗位",             
             "入职时间",         
             "缴费公司",         
             "姓名",  
             "账号",  
             "身份证号",         
             "银行名称",         
             "银行卡号",         
             "用户状态",         
             "所属年",           
             "所属月",           
             "用工性质",         
             "工资类型",         
             "应出勤天数",       
             "实际出勤天数",     
             "办事处出勤天数",   
             "回公司天数",       
             "探亲天数",         
             "请假天数",         
             "值班剩余",         
             "加班天数",         
             "夜班天数",         
             "岗位工资",         
             "职务工资",         
             "职称工资",         
             "年功工资",         
             "日工资出勤天数",   
             "日工资单价",       
             "日工资小计",       
             "计件天数",         
             "计件工资",         
             "工人系数",         
             "缺勤扣款",         
             "基本工资小计",     
             "证书补贴",         
             "值班补贴",         
             "加班补贴",         
             "晚班补贴",         
             "特岗补贴",         
             "伙食补贴",         
             "驻外补贴",         
             "实际驻外补贴",     
             "车辆补贴",         
              "正负激励",
              "学历补贴",
             "实际学历补贴",
             "探亲补贴",         
             "其他补贴",         
             "质量补贴",         
             "零工",             
             "值班日保健费",     
             "日保健费",         
             "保健费",           
              "绩效工资",
              "实发职能优化绩效",
             "租房补贴",         
             "补贴小计",         
             "应发工资",         
             "住房公积金扣除",   
             "保险扣除",         
             "个人所得税",       
             "个人欠款",         
             "爱心基金",         
             "其他扣款",         
             "上半月以发放",     
             "实发工资",         
              "备注",             
             "人员类型",
             //"伙食单价",         
             //"个税基数扣除项",   
          ];
          var filter = [
              "Factory",
              "UserDpt",
              "UserPost",
              "EntryTime",
              "PaymentAccount",
              "UserName",
              "UserCode",
              "IDCard",
              "BankCardName",
              "BankCardCD",
              "UserState",
              "RegYear",
              "RegMonth",
              "ContractNature",
              "SalaryType",
              "UserAttend",
              "UserRealAttend",
              "OutAttend",
              "BackFactoryDay",
              "BackFamilyDa",
              "AskDay",
              "OndutyDay",
              "AddDay",
              "NigtyDay",
              "WorkSalary",
              "PostSalary",
              "PostionSalary",
              "YearSalary",
              "DayAttend",
              "DaySalary",
              "DaySalaryCount",
              "PieceDay",
              "PieceSalary",
              "WorkerMod",
              "LeaveSalary",
              "BasicSalary",
              "CertSalary",
              "OnWorkSalary",
              "AddWorkSalary",
              "NigtySalary",
              "SpecialSalary",
              "EateSalary",
              "OutsideSalary",
              "RealOutsideSalary",
              "BusMoney",
              "PowerSalary",
              "EducationSub",
              "RealEducationSub",
              "BackFamilySalary",
              "OtherMoney",
              "QualitySalary",
              "OddSalary",
              "OndutyCareSalary",
              "CareDaySalary",
              "CareSalary",
              "PerfSalary",
              "RealPositionPerformance",
              "HouseSalary",
              "SubsidySalary",
              "ShouldSalary",
              "AccumuSalary",
              "SafeMoney",
              "PersonTaxSalary",
              "SellSalary",
              "LoveMoney",
              "OtherSalary",
              "IssuedSalary",
              "UserMoney",
              "Remark",
              "JobType",
              //"EateDaySalary",
              //"PersonBasicSalary",
          ];

          var name = "薪资月度";
          this.excelData(res.data.response, header, filter, name);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    excelData(res, header, filter, name) {
      require.ensure([], () => {
        const {
          export_json_to_excel,
        } = require("../../../util/Export2Excel.js");
        const tHeader = header;
        const filterVal = filter;
        const list = res;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, name);
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>

